html {
  box-sizing: border-box;
  font-size: 100%;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  background-color: #f6f8ff;
  color: rgba(32, 28, 41, 1);
  font-family: -apple-system, BlinkMacSystemFont, "Open Sans", "Helvetica Neue",
    sans-serif;
  line-height: 1.5;
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.3;
  margin: 0.25em 0 0.5em;
}

h1 {
  font-size: 3.157em;
}

h2 {
  font-size: 2.369em;
}

h3 {
  font-size: 1.777em;
}

h4 {
  font-size: 1.333em;
}

h5 {
  font-size: 1em;
}

h6,
input,
textarea,
select,
option {
  font-size: 0.75em;
}

figure {
  margin: 2.25em 0;
}

img {
  max-width: 100%;
  height: auto;
}

p {
  margin: 0 0 1em;
}

a {
  color: inherit;
}

@media only screen and (min-width: 30em) {
  html {
    font-size: 112.5%;
  }

  h1,
  h2,
  h3,
  h4 {
    line-height: 1.2;
  }
}

@media only screen and (min-width: 64em) {
  html {
    font-size: 125%;
  }

  h1,
  h2,
  h3,
  h4 {
    line-height: 1.1;
  }

  h6,
  input,
  textarea,
  select,
  option {
    font-size: 0.75em;
  }
}
